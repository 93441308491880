<template>
    <div class="TimeDiv">
        <p>{{ timer }}</p>
        <p>{{ sed }}</p>
    </div>

    <div class="TimeDivText">
        <p ref="typed"></p>
    </div>
</template>
<script>
import Typed from 'typed.js';  
export default{
    name:"TimE",
    data(){
        return{
            timer:"Time",
            sed:"sed",
            types:null
        }
    },
    mounted(){
        let vm = this;
        function refreshDate(){
            var k = new Date();
            vm.timer = k.getFullYear() + "年" + (k.getMonth() + 1  ) + "月" + k.getDate() + "日";
            vm.sed = k.getHours() + ":" + k.getMinutes() + ":" + k.getSeconds();
        }
        refreshDate();
        setInterval(() => {
            refreshDate();
        }, 1000);
        const dom = this.$refs.typed;
        this.types = new Typed(dom,{
            strings: ["梦想是远方的灯塔，只有坚持不懈，才能抵达成功的彼岸。", "WebSite Build By Vue3.", "太阳每天都是新的，你是否每天都在努力？","青衣茗月 | EquityQ","FrameWork：BootStrap.","热爱所隔山海 | 奋斗触及梦想。"], // 要显示的字符串数组  
            typeSpeed: 40, // 打字速度  
            backSpeed: 40, // 回退速度  
            loop: true, // 是否循环  
            loopCount: null, // 循环次数，null表示无限循环  
            showCursor: false, // 是否显示光标  
            cursorChar: "|", // 光标字符  
            shuffle: true, // 是否随机播放字符串数组中的项  
        })
    },beforeUnmount(){
        this.types.destroy();
    }
}
</script>
<style>
.TimeDiv{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}
.TimeDivText{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.TimeDiv p,.TimeDivText p{
    color: white;
    font-size: 2rem;
    font-weight: 100;
    font-family: 'SimHei';
    letter-spacing: 5px;
}
</style>