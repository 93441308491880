<template>
    <div class="FooterDiv">
        <h3 @click="Clicking(index)" v-for="(text,index) in msglist" :key="index" class="text" :id="'footer_'+index">{{ text }}</h3>
    </div>
</template>
<script>
export default{
    name:"FooTer",
    data(){
        return{
            msglist:[
                '蜀ICP备2023026466号',
                'Copyright © 2024 青衣茗月 保留所有权利',
                "Build By EquityQ",
                "Use Vue3 & BootStrap Framework"
            ],Clicking(a){
                if(a==0){
                    window.open('https://beian.miit.gov.cn/',"_blank")
                }
            }
        }
    }
}
</script>
<style>
.FooterDiv{
    background-color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.FooterDiv .text{
    color: white;
    font-weight: 500;
    font-family: 'SimHei';
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1rem;
    padding-top: 20px;
    padding-bottom: 20px;
}
#footer_0{
    cursor: pointer;
}
</style>