<template>
    <div>
        <div class="BackGroundPic"></div>
        <Pattern></Pattern>
        <timer></timer>
        <footers></footers>
    </div>
</template>
<script>
import footers from "../components/FooTer.vue"
import Pattern from "../components/PatterData.vue"
import timer from "../components/TimE.vue"
export default{
    components:{
        footers,Pattern,timer
    }
}
</script>