<template>
<div>
    <div class="container">  
        <br>
        <br>
        <br>
        <div class="alert alert-primary sus text-center" role="alert" id="suser">
            A simple primary alert—check it out!
        </div>
        <div class="alert alert-danger wrong text-center" role="alert" id="danger">
             A simple danger alert—check it out!
        </div>
    <h2 class="text-center">西南财经大学天府学院Oracle Club动态签到系统</h2>  
    <br>
    <form id="collectForm">  
        <div class="form-group">  
            <label for="name">姓名</label>
            <input type="text" class="form-control" id="name" name="name" required>  
        </div>  
        <br>
        <div class="form-group">  
            <label for="studentId">学号</label>  
            <input type="text" class="form-control" id="studentId" name="studentId" required>  
        </div>
        <br>
        <div class="form-group">  
        <label for="direction">所属方向</label>  
            <select class="form-control" id="direction">  
                <option>IH 智能硬件</option>  
                <option>DSML 机器深度学习</option>   
            </select>  
        </div> 
        <br>  
        <div class="form-group">  
            <label for="verificationCode">动态校验码</label>  
            <input type="text" class="form-control" id="verificationCode" name="verificationCode" required>   
        </div>
        <br>
        <br>
        <div class="progress">  
            <div class="progress-bar" role="progressbar" id="cof" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>  
        </div>  
        <br>
        <div class="d-flex justify-content-center">
            <button type="button" @click="submiting" class="btn btn-primary mt-3 btns">提交</button>  
        </div>
    </form>
    <br>
    <div>
        <h2>注意事项：</h2>
        <br>
        <p>1：请输入对应正确的姓名、学号、及对应动态校验码。</p>
        <p>2：由于签到系统涉及到个人信息和数据的传输，我们会尽全力保护您的个人隐私不被泄露。</p>
        <p>3：我们会定期对数据进行检查与分析，如发现假签到、代签到等情况，我们有权对您的违规记录进行删除。</p>
        <p>4：如在使用过程中遇到任何问题请及时与开发者联系，或发送邮件至qingyimingyue@qq.com</p>
        <p>5：数据更新请参见开发者文档API接口，如需申请权限请与开发者团队联系。</p>
    </div> 
    <br> 
</div>  
</div>
<footers></footers>    
</template>
<script>
import footers from '../components/FooTer.vue'
export default{
    components:{
        footers
    },mounted(){
        function isNone(e){
            if(e!=undefined && e!="" && e!=null){
                return true;
            }
            return false;
        }
        var mid= {
            name:false,
            studentid:false,
            code:false
        }
        function setwidth(){
            var d = 25;
            if(mid.name){
                d = d + 25;
            }
            if(mid.studentid){
                d= d+25;
            }
            if(mid.code){
                d= d + 25;
            }
            var ed  = document.getElementById("cof")
            ed.style.width = d+ "%";
            ed.innerText = d+"%";
        }
        var k = document.getElementById('name');
        k.addEventListener('input',function(){
            if(isNone(this.value)){
                mid.name = true
            }else{
                mid.name = false
            }
            setwidth()
        });

        var d = document.getElementById("studentId");
        d.addEventListener('input',function(){
            if(isNone(this.value)){
                mid.studentid = true
            }else{
                mid.studentid = false
            }
            setwidth()
        });

        var f = document.getElementById("verificationCode");
        f.addEventListener('input',function(){
            if(isNone(this.value)){
                mid.code = true
            }else{
                mid.code = false
            }
            setwidth()
        });
    },methods:{
        submiting:function(){
            var data = {
                name : document.getElementById("name").value,
                id:document.getElementById("studentId").value,
                direction:document.getElementById("direction").value,
                code:document.getElementById("verificationCode").value
            }
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            const urlencoded = new URLSearchParams();
            urlencoded.append("id", data.id);
            urlencoded.append("name",data.name);
            urlencoded.append("code",data.code);
            urlencoded.append("direction", data.direction);

            const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
            };
            
            function printing(a){
                var k = document.getElementById("suser");
                var f = document.getElementById("danger");
                var t = JSON.parse(a);
                var currentTime = new Date().toLocaleString();
                console.log(t.status);
                f.style.display = 'none';
                k.style.display = 'none';
                setTimeout(() => {
                    if(t.status==200){
                        k.innerText = t.message + " 响应时间："+currentTime;
                        k.style.display = 'block';
                    }else{
                        f.innerText = "响应码："+t.status+"  原因："+ t.message +"  响应时间："+currentTime;
                        f.style.display = 'block';
                    }
                }, 500);
            }
            fetch("https://equityq.cn/api/attendance", requestOptions)
            .then((response) => response.text())
            .then((result) => printing(result))
            .catch((error) => console.error(error));

        }
    }
}
</script>
<style>
.btns{
    width: 200px;
    height: 40px;
}
.progress{
    height: 30px;
    border-radius: 10px
}
.alert{
    display: none;
    z-index: -1;
}
</style>