<template>
    <div :class="Classing" id="localBanner">
        <nav class="navbar navbar-expand-sm bg-black navbar-dark">
            <span class="text_banner ofc">青衣茗月</span>
            <img src="../assets/picd.jpg" alt="picd.png" class="logo ml-2">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item"  v-for="(text,index) in msg" :key="index">
                    <router-link class="opt" :to="text[1]"><a class="nav-link">{{text[0]}}</a></router-link>
                </li>
            </ul>
        </div>  
        </nav>
    </div>

    <transition name="fade" mode="out-in">
        <router-view />
    </transition>
</template>
<script>
export default{
    name:'ToperBanner',
    data(){
    return{
        breakpx:500,
        Classing:'T_Banner',
        info:"bannetext",
        msg:[
            ["首页","/"],
           ["签到系统","/attandence"],
            ["开发文档","/devlopment"],
            ["云储存空间","/cloudspace"],
            ["联系我们","/connection"]
        ],
    }
    },
}
</script>
<style>
.text_banner{
    color: white;
    font-size: large;
    font-family: "SimHei";
    font-weight: 100;
}
.logo{
    border-radius: 50px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.ofc{
    padding-left: 5px;
    font-size: large;
}
.nav-item .opt .nav-link{
    color: white;
    font-size: large;
    letter-spacing: 2px;
    font-family: "SimHei";
    font-weight: 100;
}
.opt{
    cursor: pointer;
    text-decoration-line: none;
}
#localBanner{
    position: fixed;
    width: 100%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from{
  opacity: 0;
}
.fade-leave-to {
    opacity: 0;
}
</style>