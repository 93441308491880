<template>
    <div class="PD_Div">
        <h1 class="fontcolor">青衣茗月的博客</h1>
        <div class="line"></div>
        <h3 class="fontcolor">EquityQ's Blog</h3>
        <h3 class="fontcolor">↓</h3>
    </div>
</template>
<script>
export default{
    name:"PatternData",
    data(){
        return{

        }
    },mounted(){
        this.$nextTick(() => {
            setTimeout(() => {
                document.querySelectorAll('.fontcolor').forEach(function(element) {
                element.style.opacity =1;
            });
            document.querySelectorAll('.line').forEach(function(element) {
                element.style.opacity =1;
            });
            }, 500);
        })
    }
}
</script>
<style>
.PD_Div {  
    width: 100%; /* 修正拼写错误 */  
    height: calc(100vh);  
    text-align: center;  
    display: flex; /* 使用 flexbox 以垂直居中内容 */  
    flex-direction: column; /* 垂直排列子元素 */  
    justify-content: center; /* 在垂直方向上居中内容 */  
    align-items: center; /* 在水平方向上居中内容 */  
}  
  
.fontcolor {  
    font-family: 'SimHei';  
    font-weight: 500;  
    color: white;  
    letter-spacing: 1rem;  
    transition: 1s;
    opacity: 0;
    padding: 10px 10px 10px 10px;
}  
  
.line{  
    width: 30%;  
    background-color: white;  
    height: 1px;  
    border-radius: 5px;  
    margin-left: auto; /* 自动外边距将元素推到右侧 */  
    margin-right: auto; /* 自动外边距将元素推到左侧 */  
    display: block; /* 确保这是一个块级元素 */  
    transition: 1s;
    opacity: 0;
}
</style>