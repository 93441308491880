// router.js
import { createRouter, createWebHashHistory } from 'vue-router';
 
// 引入组件
import IndexPage from './fotch/IndePage.vue';
import AttendencePage from './fotch/AttenDence.vue';
 
// 定义路由
const routes = [
  { path: '/', component: IndexPage },
  { path: '/attandence', component: AttendencePage },
];
 
// 创建router实例
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
 
export default router;